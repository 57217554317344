import React, {useMemo} from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react";


import { useCollection } from "react-firebase-hooks/firestore";
import { collection,orderBy, query } from "firebase/firestore";
import db from "../../firebase";
import CardChart from "../../widgets/cards/card-chart";
import ScrapesSummary from "../../components/ScrapesSummary";

import PriceChangeSummary from "../../components/PriceChangeSummary";
import { periodToDate } from "../../utils";
import ManufacturerListingTotals from "../../components/ManufacturerListingTotals";

let options = {
  snapshotListenOptions: { includeMetadataChanges: true },
}
const manuHistoryQuery = query(
  collection(db, `listing_totals_history_manufacturer`), 
  orderBy(`dt`)
);

const manuHistoryQueryAll = query(
  collection(db, `listing_totals_history`), 
  orderBy(`dt`)
);

const excludeDates = ["2024-10-18"];

export function PPHome() {

  const [value, loading] = useCollection(manuHistoryQuery, options);
  const [valueAll, loadingAll] = useCollection(manuHistoryQueryAll, options);

  const totalListingHistoryBySource = useMemo(()=>{

    const conrollerPoints = [];
    const planecheckPoints = [];
    const tradeAPlanePoints = [];
    const globalairPoints = [];


    for (let i = 0;i<valueAll?.docs.length;i++){
      
      const data = valueAll.docs[i].data()
            
      const retC = {
        x: data.dt.toDate().getTime(),
        y: data.c
      }

      const retP = {
        x: data.dt.toDate().getTime(),
        y: data.p
      }

      const retT = {
        x: data.dt.toDate().getTime(),
        y: data.tap ? data.tap : 0
      }

      const retG = {
        x: data.dt.toDate().getTime(),
        y: data.g ? data.g : 0
      }

      const isoDate = data.dt.toDate().toISOString().split("T")[0];
      if (!excludeDates.includes(isoDate)){
        conrollerPoints.push(retC);
        planecheckPoints.push(retP);
        tradeAPlanePoints.push(retT);
        globalairPoints.push(retG);

      }
    }

    const series = [
      {
        name: "Controller",
        data: conrollerPoints,
      },
      {
        name: "Planecheck",
        data: planecheckPoints,
      },
      {
        name: "Trade-a-plane",
        data: tradeAPlanePoints,
      },
      {
        name: "Globalair",
        data: globalairPoints,
      }
    ];

    return {
      title: `Total listings`,
      series: series
    }

  }, [valueAll]);

  const totalListingHistory = useMemo(()=>{

    const datAll = [];

    for (let i = 0;i<valueAll?.docs.length;i++){
      
      const data = valueAll.docs[i].data();
      const ret = {
        x: data.dt.toDate().getTime(),
        y: data.t
      }
      const isoDate = data.dt.toDate().toISOString().split("T")[0];
      if (!excludeDates.includes(isoDate)){
        datAll.push(ret);
      }
    }

    const series = [
      {
        name: "All listings",
        data: datAll,
      }
    ];

    return {
      title: `Total listings`,
      series: series
    }

  }, [valueAll]);

  const totalListingHistory7days = useMemo(()=>{

    const period = "7d";
    const datAll = [];

    for (let i = 0;i<valueAll?.docs.length;i++){
      
      const data = valueAll.docs[i].data();
      const ret = {
        x: data.dt.toDate().getTime(),
        y: data.t
      }
      const sevenDaysAgo = periodToDate(period);
      const isoDate = data.dt.toDate().toISOString().split("T")[0];
      if (!excludeDates.includes(isoDate) && data.dt.toDate() > sevenDaysAgo){
        datAll.push(ret);
      }
    }

    const series = [
      {
        name: `All listings ${period}`,
        data: datAll,
      }
    ];

    return {
      title: `Total listings (${period})`,
      series: series
    }

  }, [valueAll]);


  const manuHistChartData = useMemo(()=>{

    const datCirrus = value?.docs.map((d)=> {return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown.cirrus?.value}})
    const datCessna = value?.docs.map((d)=> {return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown.cessna?.value}})
    const datPiper = value?.docs.map((d)=> {return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown.piper?.value}})
    const datBeechcraft = value?.docs.map((d)=> {return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown.beechcraft?.value}})
    
    const series = [
      {
        name: "Cirrus",
        data: datCirrus,
        dataLabels: false,
      },
      {
        name: "Cessna",
        data: datCessna,
        dataLabels: false,
      },
      {
        name: "Piper",
        data: datPiper
      },
      {
        name: "Beechcraft",
        data: datBeechcraft
      }
    ];

    return {
      title: `Total listings per manufacturer`,
      series: series
    }


  }, [value])


  // console.log(`manu breakdown history`, value?.docs.map((m)=>m.data().dt.toDate()));

  return (
    <div className="mt-12">
 
        <ManufacturerListingTotals></ManufacturerListingTotals>

     

      {/* <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
          <StatisticsCard
            key={title}
            {...rest}
            title={title}
            icon={React.createElement(icon, {
              className: "w-6 h-6 text-white",
            })}
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={footer.color}>{footer.value}</strong>
                &nbsp;{footer.label}
              </Typography>
            }
          />
        ))}
      </div> */}
      
      {/* <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        {statisticsChartsData.map((props) => (
          <StatisticsChart
            key={props.title}
            {...props}
            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp;{props.footer}
              </Typography>
            }
          />
        ))}
      </div> */}

      <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <CardChart 
            data={totalListingHistoryBySource}
            isLoading={loadingAll}
          />
          <CardChart 
            data={totalListingHistory}
            isLoading={loadingAll}
          />
          <CardChart 
            data={totalListingHistory7days}
            isLoading={loadingAll}
          />
          <CardChart 
            data={manuHistChartData}
            isLoading={loading}
          />
      </div>
      
      <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3 ">
      <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 p-6"
          >
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Latest Scrapes
            </Typography>
          </CardHeader>
          <CardBody className="pt-0 overflow-y-auto">
            <ScrapesSummary></ScrapesSummary>
          </CardBody>
        </Card>
          
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 p-6"
          >
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Latest Price Changes
            </Typography>

          </CardHeader>
          <CardBody className="pt-0 overflow-y-auto">
            <PriceChangeSummary></PriceChangeSummary>
          </CardBody>
        </Card>
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 p-6"
          >
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Motivated Sellers
            </Typography>

          </CardHeader>
          <CardBody className="pt-0 overflow-y-auto">
            <p>TODO</p>
          </CardBody>
        </Card>    

      </div>
    </div>
  );
}

export default PPHome;
