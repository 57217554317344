import {
  collection, orderBy, query,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Loading from "../widgets/layout/loading";
import './ScrapesSummary.css';
import db from '../firebase';
import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';
import CardChart from '../widgets/cards/card-chart';
import { periodToDate } from '../utils';

let options = {
  snapshotListenOptions: { includeMetadataChanges: true },
}

function ManufacturerListingTotals() {

  const dataContext = useContext(DataContext);
  const userCustomData = dataContext.getCustomUserData();

  const homeManufacturerSelected = () =>{
    return userCustomData?.data() && userCustomData.data().homeManufacturer.value.ids;
  }

  const homeManufacturer = userCustomData?.data().homeManufacturer.value.ids[0];
  
  const manuBreakdownQuery = query(
      collection(db, `listing_totals_history_manufacturer`), 
      orderBy(`dt`)
    )

  const [value, loading, error] = useCollection(manuBreakdownQuery, options);

  const data = value?.docs.map((d)=>{
    return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown[homeManufacturer].value}
  });

  const sevenDaysAgo = periodToDate("7d");
  const filteredData = value?.docs.filter((d)=> d.data().dt.toDate() >= sevenDaysAgo)
  
  const data7days = filteredData?.map((d)=>{
    return {x: d.data().dt.toDate().getTime(), y: d.data().breakdown[homeManufacturer].value}
  });

  const series = [
    {
      name: homeManufacturer,
      data: data,
    }
  ];

  const series7days = [
    {
      name: homeManufacturer,
      data: data7days,
    }
  ];

  const chartData = data ? {
    title: `Total listings - ${homeManufacturer}`,
    series: series
  } : [];

  const chartData7Days = data ? {
    title: `Total listings - ${homeManufacturer} (7 days)`,
    series: series7days
  } : [];




  // console.log("Manufacturer listing totals value:", chartData)

  if (!homeManufacturerSelected()){
    return <span>No Home manufacturer selected</span>
  }

  if (loading) return (
    <Loading></Loading>
  )

  return (
      <div className='h-full'>
        {(error) && <strong>Error:{JSON.stringify(error)}</strong>}

        <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
          <CardChart 
            data={chartData}
            isLoading={loading}
          />
          <CardChart 
            data={chartData7Days}
            isLoading={loading}
          />
          <CardChart 
            data={chartData}
            isLoading={loading}
          />
          <CardChart 
            data={chartData}
            isLoading={loading}
          />

        </div>

      </div>
  );
}

export default ManufacturerListingTotals;
