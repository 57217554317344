import {
  collection, orderBy, query, limit,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Loading from "../widgets/layout/loading";
import './ScrapesSummary.css';
import { formatSource, friendlyTimeFormat } from '../utils';
import db from '../firebase';
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { getUniqueListingIdsForScrape } from '../fb-functions';

const options = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

function totalScrapedListingsCount(value){
  let total = 0;
  value.forEach((v)=>{
    total += scrapedListingCount(v)
  })
  return total;
}

const qController = query(
  collection(db, `controller_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const qPlanecheck = query(
  collection(db, `planecheck_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const qTradeAPlane = query(
  collection(db, `tradeaplane_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const qGlobalair = query(
  collection(db, `globalair_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const scrapedListingCount = (dat) => {
  if (!dat) return 0;
  if (dat.totalCounter) return dat.totalCounter;
  if (!dat.totalListingCountBreakdown) return 0;
  const sum = Object.values(dat.totalListingCountBreakdown).reduce((a, b) => a + b, 0);
  return sum;
};

const percentageProgress = (dat) => {
  const sum = scrapedListingCount(dat);
  return Math.round(sum / dat.initialListingCount * 100);
};

  // const totalTimeTaken = (doc) => {
  //   if (!doc.data().updated || !doc.data().started){
  //     return "N/A";
  //   } 
  //   const diff = doc.data().updated.toDate() - doc.data().started.toDate();
  //   return (diff /1000/60).toFixed(1) + " min";
  // };
  const SECONDS = 10 * 1000;


function ScrapesSummary() {

  const [valueC, loadingC, errorC] = useCollection(qController, options);
  const [valueP, loadingP, errorP] = useCollection(qPlanecheck, options);
  const [valueT, loadingT, errorT] = useCollection(qTradeAPlane, options);
  const [valueG, loadingG, errorG] = useCollection(qGlobalair, options);

  const [initialListingCountMap, setInitialListingCountMap] = useState({});
  const [value, setValue] = useState([]);
  const [uniqueScrapesValue, setUniqueScrapesValue] = useState([]);
  const [totalListingCount, setTotalListingCount] = useState(0);


  useEffect(()=>{
    

    const getUniqueListingData = async () => {
      // console.log(`running getUniqueListingData`)
      const newUniqueScrapesValue = {...uniqueScrapesValue};
      const proms = [];
      for(let i =0;i<value.length;i++){
        const data = value[i];

        // TODO
        const currentUniqueScrapesSize = uniqueScrapesValue[data.id];
        const initialListingCount = initialListingCountMap[data.id] ;

        // console.log(`checking ${data.id}  current: ${currentUniqueScrapesSize} initial: ${initialListingCount}`)

        if ((currentUniqueScrapesSize < initialListingCount) || !uniqueScrapesValue[data.id]){
          // console.log(`incomplete... getting ids`)
          proms.push(getUniqueListingIdsForScrape({
            source: data.source,
            scrapeId: data.id
          }));
        } else {
          // console.log(`not getting unique ids, already complete`);
        }
      }

      console.log(new Date(), `requesting unique ids for ${proms.length} scrapes`);
      const res = await Promise.allSettled(proms);

      res.forEach((r)=>{

        // console.log(`UNIQUE RES`,r)
        if (r.status === "fulfilled"){
          if (r.value.success){
            newUniqueScrapesValue[r.value.data.scrapeId] = r.value.data.size;
          }
        }
        // console.log(r.data.scrapeId, r.data.size)
      });
      setUniqueScrapesValue(newUniqueScrapesValue)

    }

    const interval = setInterval(() => {
      if (value){
        const newTotalListingsCount = totalScrapedListingsCount(value);
        if (totalListingCount !== newTotalListingsCount){
          console.log(`change detected getting new unique listing data NEW: ${newTotalListingsCount} Prev: ${totalListingCount}`)
          getUniqueListingData()
          setTotalListingCount(newTotalListingsCount);      
        } else {
          console.log(`No change detected NEW: ${newTotalListingsCount} Prev: ${totalListingCount}`)
        }
      }
      console.log(`running interval...`, new Date())
    }, SECONDS);

    return () => clearInterval(interval)
    
  })

  useEffect(()=>{

    if (!loadingC && !loadingP && !loadingT && !loadingG){
      if (valueC && valueP && valueT && valueG){
        const pData = valueP.docs.map((d)=> {return {id: d.id, source: "planecheck", ...d.data()}})
        const cData = valueC.docs.map((d)=> {return {id: d.id, source: "controller", ...d.data()}})
        const tData = valueT.docs.map((d)=> {return {id: d.id, source: "tradeaplane", ...d.data()}})
        const gData = valueG.docs.map((d)=> {return {id: d.id, source: "globalair", ...d.data()}})

        let allData = pData.concat(cData).concat(tData).concat(gData);
        allData = allData.sort((a, b) => b.started.toDate() - a.started.toDate())

        const initialListingCountMap = {};
        allData.forEach((el)=>{
          initialListingCountMap[el.id] = el.initialListingCount;
        })
        setInitialListingCountMap(initialListingCountMap);
        setValue(allData)
      }
    }

  }, [valueC, valueP, valueT, valueG, loadingC, loadingP, loadingT, loadingG])


  if (loadingC || loadingP) return (

    <Loading></Loading>
  )

  return (
      <div className='h-full'>
        {(errorP || errorC || errorT) && <strong>Error:{JSON.stringify(errorP)}:{JSON.stringify(errorC)}:{JSON.stringify(errorT)}{JSON.stringify(errorG)}</strong>}
        {value.map(
              (d, key) => (
                <div key={`${d.id}_${key}`} className="grid grid-cols-12 items-center justify-center gap-5 py-2">
                  
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {formatSource(d.source)}
                    </Typography>
                  </div>
                  <div className='col-span-4'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {friendlyTimeFormat(d.started)}
                    </Typography>
                    <Typography
                      variant="small"
                      className="text-xs font-medium text-blue-gray-500"
                    >
                      {d.id}  
                    </Typography>
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {percentageProgress(d)}%
                    
                    </Typography>
                    
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {d.initialListingCount} ({scrapedListingCount(d)})
                    </Typography>
                    
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {(uniqueScrapesValue  && uniqueScrapesValue[d.id]) ? uniqueScrapesValue[d.id] : "loading..."}
                    
                    </Typography>
                    
                  </div>
                </div>
              )
            )}
      </div>
  );
}

export default ScrapesSummary;
