import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { UserAuth } from "./AuthContext";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../firebase";
import { getDashboardAnalysis } from "../fb-functions";
import { UserData } from "./UserContext";

const DataContext = createContext()

const options = {
  snapshotListenOptions: { includeMetadataChanges: false},
};

function dataChangedForPanel(panelId, newPanelData, existingData, userData){
  const existingPanelData = existingData[panelId][0];
  const existingsExcludeListings = existingData[panelId][2];
  // console.log(`checking data change for panel ${panelId}`,userData, userData.excludeListings, existingsExcludeListings)
  let userDataChanged = false;
  if (userData && userData.excludeListings && existingsExcludeListings){
    // console.log("exclude listings", userData.excludeListings.length, existingsExcludeListings.length);
    userDataChanged = existingsExcludeListings.length !== userData.excludeListings.length;
  }
  const changed = JSON.stringify(newPanelData) !== JSON.stringify(existingPanelData);
  return changed || userDataChanged;
}

export const DashboardContextProvider = ({ children }) => {
  
  const [data, setData] = useState({});
  const [panelData, setPanelData] = useState([]);
  const [selectedPanelId, setSelectedPanelId] = useState("")
  const { userData } = UserData();

  // console.log("DataProvider rendered"); // Track provider renders

  const { user } = UserAuth();
  const col = "user_data";
  const userDataPanelsQuery = query(collection(db, `${col}/${user.uid}/dPanels`), orderBy("created"));
  const [snapshot, loading, error] = useCollection(userDataPanelsQuery, options);

  const [loadingDashPanelData, setLoadingDashPanelData] = useState(true);

  // Function to fetch data
  useEffect(() => {
    // console.log("Fetching data");
    
    


    const getNewData = async () =>{
      
      if (snapshot && !loading && user){
        // console.log(`dash panel loading = true`)
        setLoadingDashPanelData(true);
        // console.log("FETCHING PANELS DATA:", snapshot.docs.length);
  
        const newData = {...data};
        const newPanelData = [];
        let wasUpdated = false;
  
        for(let i = 0; i<snapshot.docs.length; i++){
  
          const panel = snapshot.docs[i];
          const specs = { ...panel.data(), userId: user.uid, panelId: panel.id};
          if (!specs.deleted){
            newPanelData.push(specs);
          }
          if (!data[panel.id]|| dataChangedForPanel(panel.id, panel.data(), data, userData)){

            if (specs.deleted){
              if (newData[panel.id]) {
                wasUpdated = true;
                delete newData[panel.id]
              }
            } else {
              const res = await getDashboardAnalysis(specs);
              if (res.success){
                wasUpdated = true;
                newData[panel.id] = [panel.data(), res.data, userData.excludeListings];
              }
            }
          } else {
            // console.log(`No change for panel: ${panel.id}`);
          }
        }
  
        if (wasUpdated){
          // console.log(`newdata`, newData, newPanelData)
          setData(newData);
          setPanelData(newPanelData);
        }
        
        // console.log(`set dash panel loading... false`);
        setLoadingDashPanelData(false);
  
      } else{
        // console.log("FETCHING PANELS DATA [NOT READY]");
      }
      
    }
    getNewData();
  }, [snapshot, loading, user, data, userData]);

  const value = useMemo(
    () => ({ 
      data, 
      panelData, 
      loading, 
      error,
      selectedPanelId,
      setSelectedPanelId,
      user,
      loadingDashPanelData
    }), [data, panelData, loading, error, selectedPanelId, setSelectedPanelId, loadingDashPanelData, user]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};

export const useChartData = (panelId) => {
  const { data, loading, error } = useData();
  // Memoize the selected data for the specific panel
  const analysisData = useMemo(() => data[panelId] ? data[panelId][1] : null, [data, panelId]);
  return { analysisData, loading, error };
};

export const useDashboardPanelData = () => {
  const { panelData, loadingDashPanelData } = useData();
  const dashPanelData = useMemo(() => panelData || [] , [panelData]);
  return { dashPanelData, loadingDashPanelData };
};

export const useSelectPanel = () => {
  const { setSelectedPanelId } = useData();
  return setSelectedPanelId;
};

export const useSelectedPanel = () => {
  const { selectedPanelId } = useData();
  return selectedPanelId;
};