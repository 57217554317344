// import logo from './l/ogo.svg';
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout2 from "./layouts/Layout2";
import { DataContextProvider } from "context/DataContext";
import { DashboardContextProvider } from "./context/DashboardContext";
import { MarketReportDataProvider } from "./context/MarketReportContext";
import { UserDataProvider } from "./context/UserContext";

function App() {
  return (
    <div>
      <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Login></Login>}></Route>
            <Route path="/signup" element={<Signup></Signup>}></Route>
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                  <DataContextProvider>
                  <UserDataProvider>
                    <DashboardContextProvider>
                      <MarketReportDataProvider>
                          <Layout2></Layout2>
                      </MarketReportDataProvider>
                    </DashboardContextProvider>
                    </UserDataProvider>
                  </DataContextProvider>
                </ProtectedRoute>
              }
            ></Route>
          </Routes>

      </AuthContextProvider>
    </div>
  );
}

export default App;
