import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

if (window.location.href.indexOf('localhost') > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

const funcDashboardChangesAnalysis = httpsCallable(functions, "getDashboardChangesAnalysis", {"timeout": 1000 * 60 * 5});

async function getDashboardChangesAnalysis (data) {

  const startDt = new Date();
  try {
    console.log(`about to call function getDashboardChangesAnalysis`);
    const response = await funcDashboardChangesAnalysis(data);
    const res = {
      success: true,
      data: response.data
    }
    const finDate = new Date();
    console.log(`RESPONSE getDashboardChangesAnalysis`, (finDate.getTime() - startDt.getTime())/1000);
    return res;
  } catch(error){
    console.log(`!!! ERROR !!! getDashboardChangesAnalysis`, ((new Date()).getTime() - startDt.getTime())/1000); 
    console.error(error);
    return {
      success: false,
      data: null
    }
  } 
}



const funcGetUniqueListingIdsForScrape = httpsCallable(functions, "getUniqueListingIdsForScrape");

async function getUniqueListingIdsForScrape (data) {

  try {
    const response = await funcGetUniqueListingIdsForScrape(data);
    const res = {
      success: true,
      data: response.data
    }
    return res;
  } catch(error){
    console.error(error);
    return {
      success: false,
      data: null
    }
  } 
}



// const funcGetSalesDataForSpecs = httpsCallable(functions, "getSalesDataForSpecs");

// async function getSalesDataForSpecs (data) {

//   try {
//     const response = await funcGetSalesDataForSpecs(data);
//     const res = {
//       success: true,
//       data: response.data
//     }
//     return res;
//   } catch(error){
//     console.error(error);
//     return {
//       success: false,
//       data: null
//     }
//   } 
// }


const funcGetDashboardAnalysis = httpsCallable(functions, "getDashboardAnalysis");


async function getDashboardAnalysis(data) {

  try {
    
    // console.log(`getting data for panel id ${data.panelId}`, data)
    // data.userId = user.uid;
    const response = await funcGetDashboardAnalysis(data);
    return {
      success: true,
      data: response.data
    }
  } catch(error){
    console.error(error);
    return {
      success: false,
      data: null
    }
  }
}

export{
  getDashboardChangesAnalysis,
  getDashboardAnalysis,
  getUniqueListingIdsForScrape
}