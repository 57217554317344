import {
  query,
  doc
} from "firebase/firestore";
import { createContext, useState, useEffect, useContext} from "react";
import db from "../firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { UserAuth } from "./AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";

export const DataContext = createContext();

const functions = getFunctions();
const func = httpsCallable(functions, "getCollectionGroupData");
const getCollectionData = httpsCallable(functions, "getCollectionData");

const options = {
  snapshotListenOptions: { includeMetadataChanges: true },
};
const col = "user_data";

export function DataContextProvider({ children }) {

  const { user } = UserAuth();
  const customUserDataQuery = query(doc(db, `${col}/${user.uid}`));
  const [customUserData] = useDocument(customUserDataQuery, options);

  const [categoryData, setCategoryData] = useState({});
  const [manufacturerData, setManufacturerData] = useState({});
  const [modelData, setModelData] = useState({});
  const [dealerData, setDealerData] = useState({});

  const [categoryIdToFormattedName, setCategoryIdToFormattedName] = useState({});
  const [modelIdToFormattedName, setModelIdToFormattedName] = useState({});
  const [manufactureIdToFormattedName, setManufacturerIdToFormattedName] = useState({});
  const [dealerIdToFormattedName, setDealerIdToFormattedName] = useState({});

  const [selectedCategory, setSelectedCategory] = useState({label: "All Categories", value: 'All Categories'});
  const [selectedManufacturer, setSelectedManufacturer] = useState({label: "All Manufacturers", value: "All Manufacturers"});
  const [selectedModel, setSelectedModel] = useState({label: "All Models", value: "All Models"});
  const [selectedDealer, setSelectedDealer] = useState({label: "All Dealers", value: "All Dealers"});

  const defaultPeriod = "36h";
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);


  // console.log('***** DATA CONTEXT RENDER *****');

  useEffect(() =>{
    
    const categoryDataObj = {};
    const categoryIdToFriendlyNameObj = {};

    // console.time("category-get")
    func({name:"category_data"}).then((snapshot) => {
      // console.timeEnd("category-get")
      for (let i = 0; i < Object.keys(snapshot.data).length; i++) {
        
        const id = Object.keys(snapshot.data)[i];
        const data = snapshot.data[id];
        const formattedName = data.formattedName ? data.formattedName.trim() : `[${id}]`;
        categoryIdToFriendlyNameObj[id] = formattedName;
        if (formattedName){
          if (categoryDataObj[formattedName]){
            categoryDataObj[formattedName].ids.push(id);
            categoryDataObj[formattedName].models.concat(data.model);
          } else {
            categoryDataObj[formattedName] = {selected: false, ids: [id], models: data.model};
          }
        }
      }
      console.log("Category Data: ", categoryDataObj);
      setCategoryIdToFormattedName(categoryIdToFriendlyNameObj);
      setCategoryData(categoryDataObj);
    });

  },[])


  useEffect(()=>{
    const manufacturerDataObj = {};
    const manufacturerIdToFormattedNameObj = {};
    // console.time("manufacturer-get") 

    func({name:"manufacturer_data"}).then((snapshot) => {
      for (let i = 0; i < Object.keys(snapshot.data).length; i++) {
        // console.timeEnd("manufacturer-get")
        const id = Object.keys(snapshot.data)[i];
        const data = snapshot.data[id];
        const formattedName = data.formattedName ? data.formattedName.trim() : `[${id}]`;
        manufacturerIdToFormattedNameObj[id] = formattedName;
        if (formattedName){
          if (manufacturerDataObj[formattedName]){
            manufacturerDataObj[formattedName].ids.push(id);
            manufacturerDataObj[formattedName].categories.concat(data.category);
          } else {
            manufacturerDataObj[formattedName] = {selected: false, ids: [id], categories: data.category ? data.category : [] };
          }
        }
      }
      // console.log("Manufacturer Data: ", manufacturerDataObj);
      setManufacturerIdToFormattedName(manufacturerIdToFormattedNameObj);
      setManufacturerData(manufacturerDataObj);
    });

  }, [])

  const [ modelsLoading, setModelsLoading ] = useState(true);

  useEffect(() => {
   
    const modelDataObj = {};
    const modelToFriendlyNameObj = {};
    // console.time("model-get")
    func({name:"model_data"}).then((snapshot) => {
      // console.timeEnd("model-get")
      // console.log(snapshot)

      for (let i = 0; i < Object.keys(snapshot.data).length; i++) {
        
        const id = Object.keys(snapshot.data)[i];
        const data = snapshot.data[id];
        const formattedName = data.formattedName ? data.formattedName.trim() : null;

        // temp hack until we make model names unique to manufacturers
        if (!formattedName){
          continue;
        }

        modelToFriendlyNameObj[id] = formattedName;
        if (formattedName){
          if (modelDataObj[formattedName]){
            modelDataObj[formattedName].ids.push(id);
            modelDataObj[formattedName].manufacturers.concat(data.manufacturer);
            modelDataObj[formattedName].categories.concat(data.category);
          } else {
            modelDataObj[formattedName] = {selected: false, ids: [id], manufacturers: data.manufacturer, categories: data.category};
          }
        }
      }
      // console.log("Model Data: ", modelDataObj);
      setModelIdToFormattedName(modelToFriendlyNameObj);
      setModelData(modelDataObj);
      setModelsLoading(false);

    });

    const dealerDataObj = {};
    const dealerToFriendlyNameObj = {};
    // console.time("dealer-get")

    getCollectionData({name:"dealers"}).then((snapshot) => {
      for (let i = 0; i < Object.keys(snapshot.data).length; i++) {
        
        const id = Object.keys(snapshot.data)[i];
        const data = snapshot.data[id];

        const formattedName = data.formattedName ? data.formattedName.trim() : null;

        const formatted = data.formatted;
        const formattedDt = data.formattedDt;
        dealerToFriendlyNameObj[id] = {formattedName, formatted, formattedDt};
        
        // temp hack until we make model names unique to manufacturers
        if (!formattedName){
          // console.log(`no formatted name for ${id}`);
          continue;
        }
        if (dealerDataObj[formattedName]){
          dealerDataObj[formattedName].ids.push(id);
        } else {
          dealerDataObj[formattedName] = {selected: false, ids: [id]};
        }

      }
      // console.log("Dealer Data: ", dealerDataObj);
      setDealerIdToFormattedName(dealerToFriendlyNameObj);
      setDealerData(dealerDataObj);
      // console.timeEnd('dealer-get')
    });


  }, []);
  

  const getCategoryData = () => categoryData;
  const getModelData = () => modelData;
  const getManufacturerData = () => manufacturerData;
  const getDealerData = () => dealerData;
  const getRawDealerData = () => dealerIdToFormattedName;

  const getCategoryNameFromId = (id) => categoryIdToFormattedName[id] ? categoryIdToFormattedName[id] : `[${id}]`;
  const getModelNameFromId = (id) => {
    if (modelsLoading) return "Loading..."
    if (modelIdToFormattedName[id]){
      return modelIdToFormattedName[id];
    } else if (!modelIdToFormattedName[id]){
      return `[${id}]`;
    } 

    // modelIdToFormattedName[id] ? modelIdToFormattedName[id] : `[${id}]`;
  
  }
  const getManufacturerNameFromId = (id) => manufactureIdToFormattedName[id] ? manufactureIdToFormattedName[id] : `[${id}]`;
  
  const getDealerNameFromId = (id) => { 
    if (dealerIdToFormattedName[id] && dealerIdToFormattedName[id].formattedName){
      return dealerIdToFormattedName[id].formattedName;
    } else if (dealerIdToFormattedName[id] && !dealerIdToFormattedName[id].formattedName){
      return `[${id}]`;
    } else {
      return "Loading..."
    }
  }

  

  const getConstraintState = () => {
    return {
      selectedCategory, setSelectedCategory,
      selectedManufacturer, setSelectedManufacturer,
      selectedModel, setSelectedModel,
      selectedDealer, setSelectedDealer,
      selectedPeriod, setSelectedPeriod
    }
  }

  const getCustomUserData = () => customUserData;
  

  return (
    <DataContext.Provider
      value={{
        getCategoryData,
        getModelData,
        getManufacturerData,
        getDealerData,
        getRawDealerData,
        getCategoryNameFromId,
        getCustomUserData,
        getModelNameFromId,
        getManufacturerNameFromId,
        getDealerNameFromId,
        getConstraintState,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export const AppData = () => {
  return useContext(DataContext);
};
